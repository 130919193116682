<template>
  <div class="content-flow" v-if="dataList">
    <div class="slider" :style="{ border: `1px solid ${dataList.Color}` }">
      <template v-if="colorBgc == '生命之鼎'">
        <div class="slider-head" style="display: flex; justify-content: center; align-items: center;padding-top: 0;" :style="{borderBottom: `1px solid ${dataList.Color}`}">
          <!-- <template v-if="dataList.MainDemands">
            <div>{{ dataList.ProductName }}</div>
            <div>({{ dataList.MainDemands }})</div>
          </template>
          <template v-else> -->
              <div>{{ dataList.ProductName }}</div>
          <!-- </template> -->
        </div>
      </template>
      <template v-else>
        <div class="slider-head" :style="{ backgroundColor: dataList.Color }">
          <template v-if="dataList.MainDemands">
            <div>{{ dataList.ProductName }}</div>
            <div>({{ dataList.MainDemands }})</div>
          </template>
          <template v-else>
            <div style="line-height: 40px;">{{ dataList.ProductName }}</div>
          </template>
        </div>
      </template>
      <div class="slider-img">
        <img :src="dataList.ProductPic">
      </div>
    </div>
    <template v-if="dataLength == 1">
      <div class="auxiliary" :style="{ borderBottom: `1px solid ${dataList.Color}` }"></div>
      <div class="process1">
        <template v-for="item in dataList.InstructionsList">
          <div class="process-text1" :style="{ border: `1px solid ${dataList.Color}` }">
            <template v-if="item.Theme.length > 11">
              <span>{{ item.Theme }}</span>
            </template>
            <template v-else>
              <span style="line-height: 43px;">{{ item.Theme }}</span>
            </template>
          </div>
        </template>
      </div>
      <!-- <div class="auxiliary" :style="{ borderBottom: `1px solid ${dataList.Color}` }"></div>
      <div class="process1">
        <template v-for="item in dataList.InstructionsList">
          <div class="process-text1" :style="{ border: `1px solid ${dataList.Color}` }">
            <template v-if="item.Composition.length > 11">
              <span>{{ item.Composition }}</span>
            </template>
            <template v-else>
              <span style="line-height: 43px;">{{ item.Composition }}</span>
            </template>
          </div>
        </template>
      </div> -->
    </template>

    <template v-else-if="dataLength == 2">
      <div class="auxiliary" :style="{ borderBottom: `1px solid ${dataList.Color}` }"></div>
      <div class="auxiliary2-box" :style="{ border: `1px solid ${dataList.Color}`, borderRight: 'none' }"></div>
      <div class="process2">
        <template v-for="item in dataList.InstructionsList">
          <div class="process-text2" :style="{ border: `1px solid ${dataList.Color}` }">
            <template v-if="item.Theme.length > 11">
              <span>{{ item.Theme }}</span>
            </template>
            <template v-else>
              <span style="line-height: 43px;">{{ item.Theme }}</span>
            </template>
          </div>
        </template>
      </div>
      <!-- <div class="auxiliary2-box"
        :style="{ borderTop: `1px solid ${dataList.Color}`, borderBottom: `1px solid ${dataList.Color}` }"></div>
      <div class="process2">
        <template v-for="item in dataList.InstructionsList">
          <div class="process-text2" :style="{ border: `1px solid ${dataList.Color}` }">
            <template v-if="item.Composition.length > 11">
              <span>{{ item.Composition }}</span>
            </template>
            <template v-else>
              <span style="line-height: 43px;">{{ item.Composition }}</span>
            </template>
          </div>
        </template>
      </div> -->
    </template>

    <template v-else-if="dataLength == 3">
      <div class="auxiliary" :style="{ borderBottom: `1px solid ${dataList.Color}` }"></div>
      <div class="auxiliary3-box"
        :style="{ borderTop: `1px solid ${dataList.Color}`, borderLeft: `1px solid ${dataList.Color}` }">
        <template v-for="item in 2">
          <div class="auxiliary3-box-item" :style="{ borderBottom: `1px solid ${dataList.Color}` }"></div>
        </template>
      </div>
      <div class="proces3">
        <template v-for="item in dataList.InstructionsList">
          <div class="process-text3" :style="{ border: `1px solid ${dataList.Color}` }">
            <template v-if="item.Theme.length > 11">
              <span>{{ item.Theme }}</span>
            </template>
            <template v-else>
              <span style="line-height: 43px;">{{ item.Theme }}</span>
            </template>
          </div>
        </template>
      </div>
      <!-- <div class="auxiliary3-box" :style="{ borderTop: `1px solid ${dataList.Color}` }">
        <template v-for="item in 2">
          <div class="auxiliary3-box-item" :style="{ borderBottom: `1px solid ${dataList.Color}` }"></div>
        </template>
      </div>
      <div class="proces3">
        <template v-for="item in dataList.InstructionsList">
          <div class="process-text3" :style="{ border: `1px solid ${dataList.Color}` }">
            <template v-if="item.Composition.length > 11">
              <span>{{ item.Composition }}</span>
            </template>
            <template v-else>
              <span style="line-height: 43px;">{{ item.Composition }}</span>
            </template>
          </div>
        </template>
      </div> -->
    </template>

    <template v-else-if="dataLength == 4">
      <div class="auxiliary" :style="{ borderBottom: `1px solid ${dataList.Color}` }"></div>
      <div class="auxiliary4-box"
        :style="{ borderTop: `1px solid ${dataList.Color}`, borderLeft: `1px solid ${dataList.Color}` }">
        <template v-for="iten in 3">
          <div class="auxiliary4-box-item" :style="{ borderBottom: `1px solid ${dataList.Color}` }"></div>
        </template>
      </div>
      <div class="proces4">
        <template v-for="item in dataList.InstructionsList">
          <div class="process-text4" :style="{ border: `1px solid ${dataList.Color}` }">
            <template v-if="item.Theme.length > 11">
              <span>{{ item.Theme }}</span>
            </template>
            <template v-else>
              <span style="line-height: 43px;">{{ item.Theme }}</span>
            </template>
          </div>
        </template>
      </div>
      <!-- <div class="auxiliary4-box" :style="{ borderTop: `1px solid ${dataList.Color}` }">
        <template v-for="item in 3">
          <div class="auxiliary4-box-item" :style="{ borderBottom: `1px solid ${dataList.Color}` }"></div>
        </template>
      </div>
      <div class="proces4">
        <template v-for="item in dataList.InstructionsList">
          <div class="process-text4" :style="{ border: `1px solid ${dataList.Color}` }">
            <template v-if="item.Composition.length > 11">
              <span>{{ item.Composition }}</span>
            </template>
            <template v-else>
              <span style="line-height: 43px;">{{ item.Composition }}</span>
            </template>
          </div>
        </template>
      </div> -->
    </template>
  </div>
</template>
<script>
export default {
  name: 'flow',
  props: ['dataList', 'dataLength', 'colorBgc'],
  data() {
    return {
      dataInfo: '',
      isShow: true,
    }
  }
}
</script>
  
<style lang="scss" scoped>
.content-flow {
  display: flex;

  .slider {
    width: 140px;
    height: 220px;
    border-radius: 5px;

    .slider-head {
      padding-top: 10px;
      border-top-right-radius: 5px;
      width: 139px;
      height: 60px;
      text-align: center;
    }

    .slider-img {
      margin: 0 auto;
      width: 58px;
      height: 125px;

      img {
        padding-top: 20px;
        width: 100%;
        height: 100%;
      }
    }
  }

  .auxiliary {
    width: 60px;
    height: 110px;
  }

  .process1 {
    width: 210px;
    height: 100%;
    padding-top: 88px;

    .process-text1 {
      width: 210px;
      height: 43px;
      text-align: center;
      border-radius: 5px;
    }
  }

  .auxiliary2-box {
    margin-top: 70px;
    width: 60px;
    height: 80px;
  }

  .process2 {
    width: 210px;
    height: 100%;
    padding-top: 50px;

    .process-text2 {
      width: 210px;
      height: 43px;
      text-align: center;
      border-radius: 5px;
    }

    .process-text2:nth-child(n + 2) {
      margin-top: 36px;
    }
  }

  .auxiliary3-box {
    margin-top: 47px;
    display: flex;
    flex-direction: column;
    width: 60px;
    height: 120px;

    .auxiliary3-box-item {
      flex: 1
    }
  }

  .proces3 {
    width: 210px;
    height: 100%;
    padding-top: 28px;

    .process-text3 {
      width: 210px;
      height: 43px;
      text-align: center;
      border-radius: 5px;
    }

    .process-text3:nth-child(n + 2) {
      margin-top: 17px;
    }
  }

  .auxiliary4-box {
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    width: 60px;
    height: 180px;

    .auxiliary4-box-item {
      flex: 1
    }
  }

  .proces4 {
    width: 210px;
    height: 100%;

    .process-text4 {
      width: 210px;
      height: 43px;
      text-align: center;
      border-radius: 5px;
    }

    .process-text4:nth-child(n + 2) {
      margin-top: 17px;
    }
  }
}
</style>