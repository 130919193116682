<template>
    <div class="contents" v-if="info">
        <div class="boxsize head">
            <div style="font-size: 60px;font-weight: bold;padding-top: 60px;">{{ info.Plate1?.Title }}</div>
            <div style="font-size: 54px">{{ info.Plate1?.Introduce }}</div>
        </div>
        <div class="boxsize infor1">
            <div class="logo">
                <img :src="info.Plate2?.BrandLogo" alt="">
            </div>
            <div class="title">{{ info.Plate2?.BrandName }}</div>
            <h3 class="tip">{{ info.Plate2?.Introduce }}</h3>
            <div class="list">
                <li v-html="info.Plate2?.Content1"></li>
                <li style="padding-top: 50px;">{{ info.Plate2?.Content2 }}</li>
            </div>
        </div>
        <div class="boxsize infor2">
            <h3 style="line-height: 50px;">{{ info.Plate3?.Title }}</h3>
            <h3>{{ info.Plate3?.Introduce }}</h3>
            <p style="padding-top: 30px;line-height: 30px; text-indent: 2em;">
                {{ info?.Plate3 | filterText }}
            </p>
        </div>
        <div class="boxsize infor3">
            <h3 style="line-height: 50px;">{{ info?.Plate4?.Title }}</h3>
            <h3>{{ info?.Plate4?.Introduce }}</h3>
            <p style="padding: 20px 0;">{{ info?.Plate4?.ItemName }}</p>
            <flow4 :dataLength="info?.Plate4.InstructionsList.length" :dataList="info?.Plate4"
                :colorBgc="info?.Plate2.BrandName" />
        </div>
        <!-- 1餐 -->
        <template v-if="info.Plate7.ProductList.length == 1">
            <div class="boxsize infor6-y">
                <h3>{{ info.Plate7.Title }}</h3>
                <p style="padding: 10px 0;">
                    <template v-if="info.Plate7.Introduce">
                        {{ info.Plate7.Introduce }}
                    </template>
                </p>
                <div class="infor6-y-main">
                    <div v-for="item in info.Plate7.ProductList">
                        <div class="y-main" style="margin-left: 165px;">
                            <div class="main-item" :style="{ border: `1px solid ${item.Color}` }">
                                <template v-if="info?.Plate2.BrandName == '生命之鼎'">
                                    <div style="display: flex;justify-content: center; align-items: center;" class="title"
                                        :style="{ borderBottom: `1px solid ${item.Color}` }">
                                        <div>{{ item.ProductName }}</div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="title" style="display: flex;justify-content: center; align-items: center;"
                                        :style="{ backgroundColor: item.Color }">
                                        <div>{{ item.ProductName }}</div>
                                    </div>
                                </template>
                                <div class="main-item-img">
                                    <img :src="item.ProductPic" alt="">
                                </div>
                            </div>
                            <p style="padding-top: 20px;">{{ item.ColorName }}</p>
                            <p>{{ item.Instructions }}</p>
                        </div>
                    </div>
                </div>
                <div style="padding: 50px 0;">
                    <p>{{ info.Plate7.Remark1 }}</p>
                    <p style="padding-top: 10px;">{{ info.Plate7.Remark2 }}</p>
                </div>
            </div>
            <div class="boxsize infor7">
                <p>{{ info.Plate8.Title }}</p>
                <div v-for="item in  info.Plate8.AttentionList">
                    <p>{{ item }} </p>
                </div>
            </div>
        </template>
        <!-- 2-4餐 -->
        <template v-else>
            <div v-if="info.Plate7.ProductList.length == 2">
                <!-- 2餐 -->
                <div class="boxsize infor4-x" v-if="info.Plate5.ItemName">
                    <p style="padding: 30px 0 ;">{{ info.Plate5.ItemName }}</p>
                    <flow6 :dataLength="info?.Plate5.InstructionsList.length" :dataList="info?.Plate5"
                        :colorBgc="info?.Plate2.BrandName" />
                </div>
                <div class="boxsize infor5-x">
                    <h3 style="padding-top: 50px;">{{ info.Plate7.Title }}</h3>
                    <p style="padding: 10px 0;">
                        <template v-if="info.Plate7.Introduce">
                            {{ info.Plate7.Introduce }}
                        </template>
                    </p>
                    <div class="infor5-x-main">
                        <div class="infor5-x-img" :style="{ border: `1px solid ${info.Plate7.ProductList[0].Color}` }">
                            <template v-if="info?.Plate2.BrandName == '生命之鼎'">
                                <div style="width: 159px;height: 38px;display: flex;justify-content: center;align-items: center;"
                                    :style="{ borderBottom: `1px solid ${info.Plate7.ProductList[0].Color}` }">
                                    <div>
                                        {{ info.Plate7.ProductList[0].ProductName }}
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div style="width: 159px;height: 38px;display: flex;justify-content: center;align-items: center;"
                                    :style="{ backgroundColor: info.Plate7.ProductList[0].Color }">
                                    <div>
                                        {{ info.Plate7.ProductList[0].ProductName }}
                                    </div>
                                </div>
                            </template>
                            <img style=" width: 39px;height: 93px;" :src="info.Plate7.ProductList[0].ProductPic">
                        </div>
                        <p>{{ info.Plate7.ProductList[0].ColorName }}</p>
                        <p>{{ info.Plate7.ProductList[0].Instructions }}</p>
                    </div>
                </div>
                <div class="boxsize infor6-x">
                    <div class="infor6-x-main">
                        <div class="infor6-x-img" :style="{ border: `1px solid ${info.Plate7.ProductList[1].Color}` }">
                            <template v-if="info?.Plate2.BrandName == '生命之鼎'">
                                <div style="width: 159px;height: 38px;display: flex;justify-content: center;align-items: center;"
                                    :style="{ borderBottom: `1px solid ${info.Plate7.ProductList[1].Color}` }">
                                    <div>
                                        {{ info.Plate7.ProductList[1].ProductName }}
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div style="width: 159px;height: 38px;display: flex;justify-content: center;align-items: center;"
                                    :style="{ backgroundColor: info.Plate7.ProductList[1].Color }">
                                    <div>
                                        {{ info.Plate7.ProductList[1].ProductName }}
                                    </div>
                                </div>
                            </template>
                            <img style=" width: 39px;height: 93px;" :src="info.Plate7.ProductList[1].ProductPic">
                        </div>
                        <p style="padding-top: 20px;">{{ info.Plate7.ProductList[1].ColorName }}</p>
                        <p>{{ info.Plate7.ProductList[1].Instructions }}</p>
                    </div>
                    <p style="padding-top: 80px;">{{ info.Plate7.Remark1 }}</p>
                    <p style="padding-top: 10px;">{{ info.Plate7.Remark2 }}</p>
                </div>
            </div>
            <div v-else>
                <div class="boxsize infor4-y">
                    <p style="padding: 8px 0;">{{ info.Plate5.ItemName }}</p>
                    <flow6 :dataLength="info?.Plate5.InstructionsList.length" :dataList="info?.Plate5"
                        :colorBgc="info?.Plate2.BrandName" />
                </div>
                <div class="boxsize infor5-y">
                    <template v-if="info?.Plate6.InstructionsList">
                        <p style="padding: 30px 0;">{{ info.Plate6.ItemName }}</p>
                        <flow6 :dataLength="info?.Plate6.InstructionsList.length" :dataList="info?.Plate6"
                            :colorBgc="info?.Plate2.BrandName" />
                    </template>
                    <template v-else>
                        <h3 style="padding: 20px 0;">{{ info.Plate7.Title }}</h3>
                        <p style="padding: 10px 0;">
                            <template v-if="info.Plate7.Introduce">
                                {{ info.Plate7.Introduce }}
                            </template>
                        </p>
                        <div class="infor5-x-main">
                            <div class="infor5-x-img" :style="{ border: `1px solid ${info.Plate7.ProductList[0].Color}` }">
                                <template v-if="info?.Plate2.BrandName == '生命之鼎'">
                                    <div style="width: 159px;height: 38px;display: flex;justify-content: center;align-items: center;"
                                        :style="{ borderBottom: `1px solid ${info.Plate7.ProductList[0].Color}` }">
                                        <div>
                                            {{ info.Plate7.ProductList[0].ProductName }}
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div style="width: 159px;height: 38px;display: flex;justify-content: center;align-items: center;"
                                        :style="{ backgroundColor: info.Plate7.ProductList[0].Color }">
                                        <div>
                                            {{ info.Plate7.ProductList[0].ProductName }}
                                        </div>
                                    </div>
                                </template>
                                <img style=" width: 39px;height: 93px;" :src="info.Plate7.ProductList[0].ProductPic">
                            </div>
                            <p>{{ info.Plate7.ProductList[0].ColorName }}</p>
                            <p>{{ info.Plate7.ProductList[0].Instructions }}</p>
                        </div>
                    </template>
                </div>
                <div class="boxsize infor6-y">
                    <template v-if="info.Plate6.ItemName">
                        <h3>{{ info.Plate7.Title }}</h3>
                        <p style="padding: 10px 0;">
                            <template v-if="info.Plate7.Introduce">
                                {{ info.Plate7.Introduce }}
                            </template>
                        </p>
                    </template>
                    <div class="infor6-y-main">
                        <template v-if="info.Plate7.ProductList.length == 3">
                            <template v-if="info?.Plate6.InstructionsList">
                                <div v-for="item in info.Plate7.ProductList">
                                    <div class="y-main">
                                        <div class="main-item" :style="{ border: `1px solid ${item.Color}` }">
                                            <template v-if="info?.Plate2.BrandName == '生命之鼎'">
                                                <div class="title"
                                                    style="display: flex;justify-content: center;align-items: center;"
                                                    :style="{ borderBottom: `1px solid ${item.Color}` }">
                                                    <div>{{ item.ProductName }}</div>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="title"
                                                    style="display: flex;justify-content: center;align-items: center;"
                                                    :style="{ backgroundColor: item.Color }">
                                                    <div>{{ item.ProductName }}</div>
                                                </div>
                                            </template>
                                            <div class="main-item-img">
                                                <img :src="item.ProductPic" alt="">
                                            </div>
                                        </div>
                                        <p style="padding-top: 20px;">{{ item.ColorName }}</p>
                                        <p>{{ item.Instructions }}</p>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div v-for="(item, index) in info.Plate7.ProductList">
                                    <template v-if="index != 0">
                                        <div class="y-main" style="padding-right: 60px;">
                                            <div class="main-item" :style="{ border: `1px solid ${item.Color}` }">
                                                <template v-if="info?.Plate2.BrandName == '生命之鼎'">
                                                    <div class="title"
                                                        style="display: flex;justify-content: center;align-items: center;"
                                                        :style="{ borderBottom: `1px solid ${item.Color}` }">
                                                        <div>{{ item.ProductName }}</div>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div class="title"
                                                        style="display: flex;justify-content: center;align-items: center;"
                                                        :style="{ backgroundColor: item.Color }">
                                                        <div>{{ item.ProductName }}</div>
                                                    </div>
                                                </template>
                                                <div class="main-item-img">
                                                    <img :src="item.ProductPic" alt="">
                                                </div>
                                            </div>
                                            <p style="padding-top: 20px;">{{ item.ColorName }}</p>
                                            <p>{{ item.Instructions }}</p>
                                        </div>
                                    </template>
                                </div>
                            </template>

                        </template>
                        <template v-else>
                            <div v-for="item in info.Plate7.ProductList">
                                <!-- 4餐 -->
                                <div class="y-main">
                                    <div class="main-item" style="width: 100px;"
                                        :style="{ border: `1px solid ${item.Color}` }">
                                        <template v-if="info?.Plate2.BrandName == '生命之鼎'">
                                            <div class="title"
                                                style="display: flex;justify-content: center;align-items: center;width: 99px;"
                                                :style="{ borderBottom: `1px solid ${item.Color}` }">
                                                <div>{{ item.ProductName }}</div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="title"
                                                style="display: flex;justify-content: center;align-items: center;width: 99px;"
                                                :style="{ backgroundColor: item.Color }">
                                                <div>{{ item.ProductName }}</div>
                                            </div>
                                        </template>
                                        <div class="main-item-img">
                                            <img :src="item.ProductPic" alt="">
                                        </div>
                                    </div>
                                    <p style="padding-top: 20px;">{{ item.ColorName }}</p>
                                    <p>{{ item.Instructions }}</p>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div style="padding: 50px 0;">
                        <p>{{ info.Plate7.Remark1 }}</p>
                        <p style="padding-top: 10px;">{{ info.Plate7.Remark2 }}</p>
                    </div>
                </div>
            </div>
            <div class="boxsize infor7">
                <p>{{ info.Plate8.Title }}</p>
                <div v-for="item in  info.Plate8.AttentionList">
                    <p>{{ item }} </p>
                </div>
            </div>
        </template>
    </div>
</template> 
<script>
import flow4 from "./components/flow4.vue"
import flow6 from "./components/flow6.vue"
import { GetInstructionOnline } from '@/api/index'

export default {
    name: 'instructionsPdfOnline',
    filters: {
        filterText: function (v) {
            let content = v?.Content.replace('{BrandName}', v.BrandName).replace('{ScriptMsg}', v.ScriptMsg)
            return content
        }
    },
    components: {
        flow4,
        flow6,
    },
    data() {
        return {
            info: '',
            isShow: false,
            tabHeadShow: false
        }
    },
    created() {
        this.getData()
    },
    methods: {
        async getData() {
            const OrderId = this.$query('orderId')
            const parmas = {
                OrderId
            }
            const { data } = await GetInstructionOnline(parmas)
            this.info = data
            // console.log('数据', data, data.Plate7.ProductList.length)
            // if (data.Plate7.ProductList.length > 2 && !data.Plate6.InstructionsList) {
            //     let aa = data.Plate7.ProductList[0]
            // }
        }
    }
}
</script>
<style lang='scss' scoped>
.contents {
    width: 550px;
    height: 3360px;
    // height: 3528px;
    padding: 0 40px;

    .boxsize {
        width: 100%;
        height: 420px;
    }

    .head {
        text-align: center;
        padding-top: 60px;
    }

    .infor1 {
        border-bottom: 1px dashed #cacacb;
        padding-top: 20px;

        .logo {
            width: 123px;
            height: 98px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .title {
            padding-top: 16px;
            font-size: 30px;
        }

        .tip {
            width: 90%;
            line-height: 40px;
            border-bottom: 1px solid #000;
        }

        .list {
            padding-top: 40px;
        }
    }

    .infor2 {
        padding-top: 30px;
        border-bottom: 1px dashed #cacacb;
    }

    .infor3 {
        padding-top: 30px;
    }

    .infor4-x {
        border-bottom: 1px dashed #cacacb;
    }

    .infor4-y {
        padding-top: 35px;
        border-bottom: 1px dashed #cacacb;
    }

    .infor5-x {
        border-bottom: 1px dashed #cacacb;

        .infor5-x-main {
            margin: 0 auto;
            text-align: center;
            width: 260px;
            height: 300px;

            .infor5-x-img {
                margin: 0px auto;
                width: 160px;
                height: 180px;

                img {
                    margin: 0 auto;
                    padding-top: 20px;
                    width: 38px;
                    height: 100px;
                }
            }
        }
    }

    .infor5-y {
        border-bottom: 1px dashed #cacacb;
    }

    .infor6-x {
        padding-top: 30px;
        border-bottom: 1px dashed #cacacb;

        .infor6-x-main {
            text-align: center;

            .infor6-x-img {
                margin: 0 auto;
                width: 160px;
                height: 180px;

                img {
                    margin: 0 auto;
                    padding-top: 20px;
                    width: 38px;
                    height: 100px;
                }
            }
        }
    }

    .infor6-y {
        padding-top: 30px;
        border-bottom: 1px dashed #cacacb;

        .infor6-y-main {
            display: flex;
            justify-content: space-between;

            .y-main {
                text-align: center;

                .main-item {
                    width: 140px;
                    height: 160px;
                    text-align: center;

                    .title {
                        width: 139px;
                        height: 38px;
                    }

                    .main-item-img {
                        margin: 0 auto;
                        width: 39px;
                        height: 93px;

                        img {
                            padding-top: 12px;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    .infor7 {
        padding-top: 20px;

        p {
            line-height: 22px;
        }
    }
}

.infor5-x-main {
    margin: 0 auto;
    text-align: center;
    width: 260px;
    height: 300px;
    padding-top: 10px;

    .infor5-x-img {
        margin: 30px auto;
        width: 160px;
        height: 180px;

        img {
            margin: 0 auto;
            padding-top: 20px;
            width: 38px;
            height: 100px;
        }
    }
}
</style>